<template>
    <div>

        <div class="loading-backdrop" v-if="saving">
            <span class="backdrop-label"> Saving database </span>
            <BaseLoader />
        </div>

        <!-- create list dialog -->
        <teleport to="body">
            <base-dialog :top="'30'" :width="'30'" :open="show_createlist" @close="closeCreateListDialog()">
                <h3 style="color: var(--font1)"> Create new list </h3>
                <div class="list-input-wrapper wwd-column">
                    <div class="list-input">
                        <it-input class="list-input" :class="{disable: is_disabled}" placeholder="Name of signal list" v-model="new_signal_list_name" prefix-icon="list" />
                        <small v-if="is_disabled" style="color: var(--red1)"> Name contains invalid characters  </small>
                    </div>
                    <div class="button-wrapper wwd-row">
                        <it-button :disabled="is_disabled" type="success" class="create-list-button success-button" :loading="creating_list" @click="createNewSignalList()">Create</it-button>
                        <it-button type="danger" class="create-list-button danger-button" @click="closeCreateListDialog()" >Cancel</it-button>
                    </div>
                </div>
            </base-dialog>
        </teleport> 
        <!-- create list dialog  -->

        <!-- delete list dialog  -->
        <teleport to="body">
            <base-dialog :top="'30'" :width="'30'" :open="show_deletelist" @close="closeDeleteListDialog()">
                <h3 class="dialog-header"> Delete list </h3>
                <div class="list-input-wrapper wwd-column">
                    <span class="font-2-basic dialog-body"> Are you sure to delete <span class="warning-color">{{chosed_signal_list}}</span> signal list?</span>
                    <div class="button-wrapper wwd-row">
                        <it-button type="success" class="create-list-button success-button" :loading="deleting_list" @click="deleteSignalList()">Delete</it-button>
                        <it-button type="danger" class="create-list-button danger-button" @click="closeDeleteListDialog()" >Cancel</it-button>
                    </div>
                </div>
            </base-dialog>
        </teleport>
        <!-- delete list dialog  -->

        <!-- view signals dialog  -->
        <teleport to="body">
            <base-dialog :top="'10'" :width="'40'" :height="'50'" :open="show_view_signals_dialog" @close="closeViewSignalsDialog()">
                <h2 style="color: var(--font1)"> Signal list: {{chosed_signal_list}} </h2>
                <div class="list-input-wrapper wwd-column">
                <DataTable class="signals-table" :value="objected_signals" stripedRows responsiveLayout="scroll">
                    <Column field="name" header="Signal list"></Column>
                </DataTable>
                <it-button type="danger" class="create-list-button danger-button" @click="closeViewSignalsDialog()" >Close </it-button>
                </div>
            </base-dialog>
        </teleport>
        <!-- view signals dialog  -->
        <ScrollPanel class="signal-lists">
            <Card class="signal-lists base-card">
                <template #title>
                    <div class="table-header wwd-row wwd-sb">
                        <span> Signal lists</span>
                        <div class="wwd-row">
                        <span class="p-input-icon-left filter-input">
                            <i class="pi pi-search" />
                            <InputText type="text" v-model="filter_input" placeholder="Search" />
                        </span>
                            <Button :loading="loading" @click="getSignalNames(true)" class="p-button p-button-text p-button-secondary" icon="pi pi-refresh" label="Refresh"></Button>
                            <Button label="Create new list" icon="pi pi-plus" class="create-list-button p-button-raised p-button-success p-button-sm" @click="openCreateListDialog()" />
                        </div>
                    </div>
                </template>
                <template #content class="content">
                <DataTable class="table" :value="setsFiltered" stripedRows responsiveLayout="scroll">
                    <Column field="name" header="Signal list"></Column>
                    <Column field="actions" header="Action">
                        <template #body="{data}">
                            <span class="mr-5"> 
                                <Button label="" icon="pi pi-eye" class="action-button p-button-raised p-button-info p-button-sm" @click="openViewSignalsDialog(data.name)" />
                            </span>
                            <span>
                                <Button label="Upload signals.txt" icon="pi pi-plus" class="action-button p-button-raised p-button-warning p-button-sm"> 
                                    <input accept=".txt" id="input" class="input" type="file" ref="file1" @change="readFile" hidden>
                                    <label @click="saveName(data.name)" class="label wwd-row wwd-sb" for="input"> <i class="pi pi-plus ico"></i>  </label>
                                </Button>    
                            </span>
                            <span>
                                <Button icon="pi pi-trash" class="action-button p-button-raised p-button-danger p-button-sm" @click="openDeleteListDialog(data.name)"/>
                            </span>
                        </template>
                    </Column>
                </DataTable>
                </template>
            </Card>
        </ScrollPanel>
    </div>
</template>

<script>
    import BaseDialog from '@/components/ui_items/BaseDialog.vue'
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import InputText from 'primevue/inputtext';
    import Card from 'primevue/card';
    import Button from 'primevue/button'
    import BaseLoader from '@/components/ui_items/BaseLoader.vue'
    import ScrollPanel from 'primevue/scrollpanel'

    export default {
        components: {
            BaseDialog,
            DataTable,
            Column,
            Card,
            Button,
            BaseLoader,
            InputText,
            ScrollPanel
        },
        data() {
            return {
                sets: [],
                creating_list: false,
                deleting_list: false,
                show_createlist: false,
                show_deletelist: false,
                show_view_signals_dialog: false,
                new_signal_list_name: '',      
                signal_names: '',
                chosed_signal_list: '',
                objected_signals: [],
                new_signal_names: null,
                is_disabled: false,
                saving: false,
                filter_input: null,
                loading: false
            }
        },
        methods: {
            async createNewSignalList() {
                this.creating_list = true
                await this.$store.dispatch('createSignalList', {
                    list: this.new_signal_list_name,
                    vm: this
                })
                await this.$store.dispatch('getSignalNames')
                this.creating_list = false
                this.chosed_signal_list = this.new_signal_list_name
                this.signalSets()
                this.closeCreateListDialog()
            },
            async deleteSignalList() {
                this.deleting_list = true
                await this.$store.dispatch('deleteSignalList', {
                    list: this.chosed_signal_list,
                    vm: this
                })
                await this.$store.dispatch('getSignalNames')
                this.deleting_list = false
                this.signal_names = ''
                this.signalSets()
                this.closeDeleteListDialog()
                await this.saveToDatabase()
            },
            async uploadSignalNames() {
                await this.$store.dispatch('uploadSignalList', {
                    list: this.chosed_signal_list,
                    signal_names: this.signal_names,
                    vm: this
                })
                await this.$store.dispatch('getSignalNames')
            },
            openCreateListDialog() {
                this.show_createlist = true
            },
            openDeleteListDialog(signal_list) {
                this.chosed_signal_list = signal_list
                this.show_deletelist = true
            },
            closeCreateListDialog() {
                this.show_createlist = false
            },
            closeDeleteListDialog() {
                this.show_deletelist = false
            },
            async openViewSignalsDialog(signal_list) {
                this.chosed_signal_list = signal_list
                await this.$store.dispatch('getSignalNames')
                this.updateSignalNames()
                this.show_view_signals_dialog = true
            },
            closeViewSignalsDialog() {
                this.show_view_signals_dialog = false
            },
            saveName(signal_list) {
                this.chosed_signal_list = signal_list
            },
            async readFile(ev) {
                try {

                    const file = ev.target.files[0];
                    const reader = new FileReader();
                    
                    if (file.name !== 'api_signals.txt') {
                        throw new Error('Invalid api signals file.')
                    }
                    
                    reader.onload = e =>  this.new_signal_names = e.target.result
                    reader.readAsText(file);
                    reader.onloadend = async () => {
                        await this.$store.dispatch('uploadSignalList', {
                            list: this.chosed_signal_list,
                            signal_names: this.new_signal_names,
                            vm: this
                        })
                        await this.saveToDatabase()
                    }
                } catch(err) {
                    this.$toast.add({severity:'error', summary: 'Error!', detail:`Invalid api signals file`, life: 5000});
                }
            },
            updateSignalNames() {
                this.objected_signals = []
                this.signal_names = this.$store.getters.signalSets[this.chosed_signal_list] 
                for (const signal of this.signal_names.split('\n')) {
                    this.objected_signals.push({name: signal})
                }
            },
            signalSets() {
                let obj = []
                for (const x of Object.keys(this.$store.getters.signalSets)) {
                    obj.push({"name": x})
                }
                this.sets = obj
            },
            async getSignalNames(force=false) {
                try {
                    this.loading = true
                    if (!this.$store.getters.signalSets || force) {
                        await this.$store.dispatch('getSignalNames')
                        if (force) {
                            this.$toast.add({severity:'success', summary: 'Success', detail:`Signal lists loaded`, life: 5000});
                        }
                    }
                    this.signalSets()
                } catch(err) {
                    this.$toast.add({severity:'error', summary: 'Error', detail:`Failed to load signal lists`, life: 5000});
                } finally {
                    this.loading = false
                }
            },
            async saveToDatabase() {
                this.saving = true
                await this.$store.dispatch('saveChanges', {vm: this})
                this.saving = false
            }
        },
        computed: {
            buttonStyle() {
                return this.chosed_signal_list === '' ? 'saved' : ''
            },
            isLoading() {
                return this.$store.getters.isLoading
            },
            setsFiltered() {
                return this.filter_input ? this.sets.filter(element => element.name.toLowerCase().includes(this.filter_input.toLowerCase())) : this.sets
            }
     
        },
        watch: {
            chosed_signal_list() {
                this.updateSignalNames()
            },
            new_signal_list_name(newValue) {
                if (newValue.includes('.') || newValue.includes(',') || newValue.includes('/')) {
                    this.is_disabled = true
                } else {
                    this.is_disabled = false
                }
            }
        },
        mounted() {
            this.getSignalNames()
        },
    }
</script>

<style scoped>
.signal-lists {
    height: 34em;
}
.table    {
    margin: 0 auto;
}
.label {
    position: relative;
    cursor: pointer;
    color: #ffffff;
    width: 100%;
    height: 100%;
}
.ico {
    font-size: 15px;
    margin-right: 2px;
    margin-top: 3px;
}
.list-input {
    width: 70%;
}
.button-wrapper {
    margin-top: 10px;
}
.create-list-button {
    margin-right: 5px;
}
.success-button {
    background-color: rgb(55, 143, 14);
    border-color: rgb(55, 143, 14);
    box-shadow: none;
}
.danger-button {
    background-color: rgb(196, 13, 0);
    border-color: rgb(196, 13, 0);
    box-shadow: none;
}
.action-button {
    margin: 0.1rem 0.2rem;
    height: 2rem !important;
    min-width: 3rem;
}
.create-list-button {
    width: 10rem;
}
.dialog-header {
    color: var(--font1);
}
.dialog-body {
    color: var(--font1);
}
.disable {
    border-color: red !important;
}
.table-header {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}
.filter-input {
    margin-right: 0.5rem;
}
</style>