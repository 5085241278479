<template>
    <div class="admin-container">
        <div class="row-container wwd-row">
            <SignalList class="signal-list-container" />
            <ScenarioList class="signal-list-container" />
        </div>
    </div>
</template>

<script>
    import SignalList from '@/components/admin/SignalList.vue'
    import ScenarioList from '@/components/admin/ScenarioList.vue'
    export default {
        components: {
            SignalList,
            ScenarioList
        }
    }
</script>

<style scoped>
.admin-container > * {
    margin-top: 1rem;
}
.row-container {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.signal-list-container {
    width: 49vw;
}

@media (max-width: 1400px) {
    .row-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
    .signal-list-container {
        margin-top: 1rem;
        width: 100%;
    }
    .statistic-chart-container {
        width: 100%;
    }
}
</style>